import React from "react"
import Layout from "../../components/layout"
import Screenshot from "../../img/client-clubsoirte-screens.jpg"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Club Soirtè</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-wrapper cover-clubsoirte">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="m-t-100 sm-m-t-20">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h4>Client</h4>
                <p className="m-b-20">Club Soirtè Pvt Ltd, Sri Lanka.</p>

                <h4>Roles</h4>
                <p className="m-b-20">Mobile App Development (iOS and Android)</p>

                <p className="m-b-20">
                  <a rel="noopener" href="http://clubsoirte.com" target="_blank">
                    clubsoirte.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                Club Soirté is a one stop, online reservation engine or booking
                and payment platform for beauty and wellness treatments.
                Offering a streamlined service, Club Soirté allows you to select
                their partnered salons, spas and wellness/medical aesthetic
                centers in real time, make reservations and payments with a
                couple of clicks and devoid of inconveniences and irritants like
                busy phones, booking confusion etc. Club Soirte also handles
                transportation through a partnered transport provider.
              </p>
            </div>
          </div>   
        </div>
      </section>
      <section>
        <div className="bg-master-lightest">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
              <blockquote className="m-t-30">
          SmashTaps was able to take Club Soirte’s vision and turn it into an 
          app that was self sufficient and useful to our clients. 
          Post production, they too have been extremely helpful and proactive with 
          bug fixes and in attending to maintenance related matters. 
          I’d recommend SmashTaps to anyone who has a vision for user friendly 
          mobile applications at an affordable price.
          </blockquote>
          <p className="">— Teruni De Silva, Founder of Club Soirte</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Clubsoirte logo" src={Screenshot} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
